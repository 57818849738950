$(window).on('load', function() {
    const btn_first = $(".btn-first");
    const btn_second = $(".btn-second");
    const title = $(".display-3");
    const subtitle_one = $("h5");
    const subtitle_two = $("h4");
    const divider = $(".divider");
    
    subtitle_one.each(function() {
        subtitle_one.addClass("animated");
        subtitle_one.addClass("fadeInDown");
        subtitle_one.css("visibility", "initial");
    });

    title.each(function() {
        title.addClass("animated");
        title.addClass("fadeInDown");
        title.css("visibility", "initial");
    });

    subtitle_one.on('animationend', function() {
        subtitle_two.each(function() {
            subtitle_two.addClass("animated");
            subtitle_two.addClass("fadeInDown");
            subtitle_two.css("visibility", "initial");
        });
    });

    subtitle_two.on('animationend', function() {
        btn_first.each(function() {
            btn_first.addClass("animated");
            btn_first.addClass("fadeInDown");
            btn_first.css("visibility", "initial");
        });
        divider.each(function() {
            divider.addClass("animated");
            divider.addClass("fadeInDown");
            divider.css("visibility", "initial");
        });
        btn_second.each(function() {
            btn_second.addClass("animated");
            btn_second.addClass("fadeInDown");
            btn_second.css("visibility", "initial");
        });
    });
});